// @file Pinia store for the electron app
import fetchElectronBridge from '@@/bits/electron_bridge'
import type { User } from '@@/types'
import { defineStore } from 'pinia'
import { ref } from 'vue'

/**
 * The state in this store will be sent to the electron app, then the electron app make decisions based on the state.
 * NOTE: Only update this store via action because the electron app listens to the action with subscribeToElectronAppStoreActions
 */
export const useElectronAppStore = defineStore('electronAppStore', () => {
  // State
  const currentUser = ref<User | Record<string, any>>({})
  const brahmsToken = ref<string>('')

  // Getters

  // Actions

  function setCurrentUser(user: User): void {
    currentUser.value = user
  }

  function setBrahmsToken(token: string): void {
    brahmsToken.value = token
  }

  return {
    // State
    currentUser,
    brahmsToken,

    // Getters

    // Actions
    setCurrentUser,
    setBrahmsToken,
  }
})

type ModuleName = 'dashboard'

/**
 * Subscribe to the electron app store actions then send the state to the electron app, so the electron app can make decisions based on the state.
 * @param {any} moduleName:ModuleName
 * @returns {any}
 */
export function subscribeToElectronAppStoreActions(moduleName: ModuleName): void {
  const electronAppStore = useElectronAppStore()
  const processableActionNames = ['setCurrentUser', 'setBrahmsToken'] // Must be the same as the action names in useElectronAppStore

  electronAppStore.$onAction((context) => {
    const actionName = context.name
    if (!processableActionNames.includes(actionName)) return
    const state = { currentUser: electronAppStore.currentUser, brahmsToken: electronAppStore.brahmsToken }
    switch (actionName) {
      case 'setCurrentUser':
        state.currentUser = context.args[0]
        break
      case 'setBrahmsToken':
        state.brahmsToken = context.args[0]
        break
    }
    const electronApp = fetchElectronBridge()
    electronApp.webAppStateHasChanged?.(moduleName, { electronApp: state })
  })
}
